<template>
	<div>
		<CCard>
			<CCardBody class="card-schedules">
				<CustomTable
					:is-create-examination="isCreateExamination"
					:total-pages="totalPages"
					:total-items="totalItems"
					@create="create"
					@search="search"
				>
					<template #headers>
						<tr>
							<th
								v-for="(header, index1) in headers"
								:key="`${header.title}-${index1}`"
								scope="col"
								:class="`${header.class}`"
								>{{ header.title }}</th
							>
						</tr>
					</template>
					<template #body>
						<template v-if="schedule && schedule.length">
							<tr
								v-for="(customer, index2) in schedule"
								:key="`${customer.id}-${index2}`"
								@click="goToAppt($event, customer.id)"
								@click.right="goToApptNewTab($event, customer.id)"
								@click.middle="goToApptNewTab($event, customer.id)"
							>
								<td scope="row" class="text-break text-pre-wrap appt-table__name">
									<div>
										<span>{{ $t("AppointmentFormTable.Username") }}: </span
										>{{ customer.customer && customer.customer.fullName }}</div
									>
									<div>
										<span>{{ $t("AppointmentFormTable.SDT") }}: </span
										>{{ customer.customer && customer.customer.phone }}</div
									>
									<div>
										<span>{{ $t("AppointmentFormTable.Birthday") }}: </span
										>{{ customer.customer && customer.customer.birthYear }}</div
									>
								</td>
								<td scope="row" class="text-pre-wrap appt-table__request"
									><template v-if="customer.requestedServices"
										><div
											v-for="(request, index3) in customer.requestedServices"
											:key="`${request.id}-${index3}`"
											>{{ request.name }}</div
										></template
									></td
								>
								<td scope="row" class="fit appt-table__clinic">
									<router-link
										:to="{
											name: 'UpdateClinic',
											params: { id: customer.clinicId },
										}"
										target="_blank"
										class="link-icon link-redirect"
									>
										{{ customer.clinic && customer.clinic.name }}
									</router-link>
								</td>
								<td scope="row" class="text-pre-wrap appt-table__appt">
									<div
										><span>{{ $t("AppointmentFormTable.Id_date") }}: </span>{{ customer.code }}</div
									>
									<div
										><span>{{ $t("AppointmentFormTable.HourDate") }}: </span
										>{{ moment(customer.planedDate).format("DD/MM/YYYY HH:MM") }}</div
									>
									<div
										><span>{{ $t("AppointmentFormTable.Note") }}: </span>{{ customer.note }}</div
									>
								</td>
								<td scope="row" :class="`fit ${getStatusClass(customer.status)}`">{{
									getStatus(customer.status)
								}}</td>
								<td scope="row" class="fit text-center actions">
									<router-link
										:to="{ name: 'AdminUpdateAppt', params: { id: customer.id } }"
										class="link-icon text-info"
										><span class="prefix-input d-inline-block"
											><font-awesome-icon
												:icon="['fas', 'eye']"
												:title="$t('Action.Edit')"
											/> </span
									></router-link>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr class="text-center">
								<td colspan="11" class="fit">
									{{ $t("Table.NoData") }}
								</td>
							</tr>
						</template>
					</template>
				</CustomTable>
				<CRow class="text-center">
					<CCol>
						<button type="button" class="btn btn-outline-danger ml-2" @click="cancel">
							{{ $t("Button.Exit") }}
						</button>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { GET_EXAMINATION_SCHEDULE } from "../store/action-types"
import { RESET_FILTERS_TABLE_DETAIL, SET_CUSTOMER_FOR_APPT } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("customers")
import { STATUS, PAGING } from "@/shared/plugins/constants"

export default {
	name: "ScheduleForm",

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			headers: [
				{
					title: this.$t("AppointmentFormTable.Name"),
					class: "text-nowrap name appt-table__name",
				},
				{
					title: this.$t("AppointmentFormTable.Request"),
					class: "text-nowrap appt-table__request",
				},
				{
					title: this.$t("AppointmentFormTable.Clinic"),
					class: "text-nowrap name appt-table__clinic",
				},
				{
					title: this.$t("AppointmentFormTable.Appoint"),
					class: "text-nowrap appt-table__appt",
				},
				{
					title: this.$t("AppointmentFormTable.Status"),
					class: "text-nowrap",
				},
				{
					title: this.$t("CustomerManagement.Actions"),
					class: "fit text-center",
				},
			],

			isCreateExamination: true,
			limit: PAGING.LIMIT,
			offset: PAGING.SKIP,
		}
	},

	computed: {
		...mapState(["schedule", "totalItems"]),

		totalPages() {
			return Math.ceil(this.totalItems / this.limit)
		},
	},

	created() {
		this.setData()
	},

	beforeDestroy() {
		this.RESET_FILTERS_TABLE_DETAIL()
	},

	methods: {
		...mapActions({
			GET_EXAMINATION_SCHEDULE,
		}),
		...mapMutations({ RESET_FILTERS_TABLE_DETAIL, SET_CUSTOMER_FOR_APPT }),

		setData: async function () {
			const params = {
				limit: this.limit,
				offset: this.offset,
				customerId: this.id,
			}
			await this.GET_EXAMINATION_SCHEDULE({ params })
		},

		getNo(no) {
			return no + 1 + (+this.offset || 0)
		},

		getCustomerResult(result) {
			return result ? this.$t(`ApptHIVResult.${this.upperCaseFirstLetter(result)}`) : ""
		},

		upperCaseFirstLetter(str) {
			str.toLowerCase()
			return str[0].toUpperCase() + str.toLowerCase().substr(1) || ""
		},

		create() {
			this.SET_CUSTOMER_FOR_APPT({ ...this.recentCustomer })
			this.$router.push({ path: "/appointments/create", query: { fromCustomer: true } })
		},

		search: async function (params = {}) {
			params.customerId = this.id
			await this.GET_EXAMINATION_SCHEDULE({ params })
		},

		goToCreateForm() {
			this.$router.push({ name: "CreateClinic" })
		},

		getStatus(status) {
			switch (status) {
				case STATUS.PENDING:
					return this.$t("AppointmentStatus.PENDING")
				case STATUS.CUSTOMER_CANCEL:
					return this.$t("AppointmentStatus.CUSTOMER_CANCEL")
				case STATUS.COMPLETE:
					return this.$t("AppointmentStatus.COMPLETE")
				default:
					return this.$t("AppointmentStatus.PENDING")
			}
		},

		getStatusClass(status) {
			return {
				[STATUS.PENDING]: "status--pending",
				[STATUS.COMPLETE]: "status--complete",
				[STATUS.CUSTOMER_CANCEL]: "status--cancel",
			}[status]
		},

		goToAppt(event, apptId) {
			if (event.target.localName === "td") {
				this.$router.push({ name: "AdminUpdateAppt", params: { id: apptId } })
			}
		},

		goToApptNewTab(event, apptId) {
			if (event.target.localName === "td") {
				let routeData = this.$router.resolve({
					name: "AdminUpdateAppt",
					params: { id: apptId },
				})
				window.open(routeData.href, "_blank")
			}
		},

		cancel: function () {
			this.$emit("cancel")
		},
	},
}
</script>

<style lang="scss">
.card-schedules {
	padding: 0;
	padding-bottom: 1.25rem;

	.custom-table .card-body {
		padding-bottom: 0;
	}
}
</style>
